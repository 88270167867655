<template>
  <div id="Manage">
    <!-- 产品管理 特权产品 规格-->
    <!-- Dialog 对话框 -->
    <!-- <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(dialogFonfig.openType, openForm)">确 定</el-button>
      </div>
    </el-dialog> -->
    <el-page-header @back="goBack"
                    style="margin-bottom:100px"
                    content="数据代理商"></el-page-header>
    <CommonManageMain>
      <template v-slot:header>
        <div>
          <CommonForm :form="searchForm"
                      :formLabel="searchformLabel"
                      class="filtrateInput">
            <el-button type="primary"
                       @click="searchKeyword(searchForm)">搜索</el-button>
            <el-button>导出</el-button>
            <el-button type="text">查看已导出列表</el-button>
            <el-button type="text"
                       @click="resetFilter(searchForm)">重置筛选条件</el-button>
          </CommonForm>
        </div>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @getPage="getPage"
                     style="height:588px">
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { getDataAgentStream } from 'api/organize.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data () {
    return {
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增商品',
        editName: '编辑商品',
        isShowDialogVisible: false
      },
      // form表单 基础数据
      openForm: {
        name: '',
        icon: '',
        sort: '',
        status: false,
      },
      openFormLabel: [
        {
          model: 'name',
          label: '特权分类名称'
        },
        {
          model: 'icon',
          label: '图标',
          type: 'upload',
          maxSize: 300,
          imgHeight: '1280',
          imgWidth: '808',
          // required: true
        },
        {
          model: 'sort',
          label: '排序'
        },
        {
          model: 'status',
          label: '状态',
          type: 'switch'
        }
      ],
      // form表单 搜索数据
      searchForm: {
        data_agent_id: '',
        type: '',
        in_out: ''
      },
      searchformLabel: [
        {
          model: 'data_agent_id',
          label: '数据代理商ID',
        },
        {
          model: 'type',
          label: '类型',
          type: 'select',
          width: '250px',
          options: [
            // value:'值',label:'标签'
            { value: 3, label: '转出' },
            { value: 2, label: '退回' },
            { value: 1, label: '提现' },
            { value: 0, label: '分润' },
          ],
        },
        {
          model: 'in_out',
          label: '方式',
          type: 'select',
          width: '250px',
          options: [
            // value:'值',label:'标签'
            { value: 1, label: '收入' },
            { value: 0, label: '支出' },
          ],
        },
      ],
      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'id',
          label: '订单ID'
        },
        {
          prop: 'title',
          label: '标题'
        },
        {
          prop: 'pre',
          label: '变动前'
        },
        {
          prop: 'amount',
          label: '金额'
        },
        {
          prop: 'last',
          label: '变动后'
        },
        {
          prop: 'in_out',
          label: '收入/支出',
          type: 'in_out'
        },
        {
          prop: 'type',
          label: '类型',
          type: 'AgentType'
        },
        {
          prop: 'create_time',
          label: '创建时间',
        },
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示选择多行数据
        selection: false
      },


      privilegeAllDataObj: {},
      searchData: '',//存放搜索的数据，分页时使用
      //被选中数据代理商ID
      dataAgent_id: '',
      // goods_id
    }
  },
  mounted () {
    this.dataAgent_id = this.$route.query.dataAgent_id//传参 数据代理商ID
    this.updataTableData()
  },
  watch: {
    //将字段转换为Elementui能用的字段
    privilegeAllDataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.privilegeAllDataObj.list,
        {
          // status: 'state',
        },
        0
      )
    },
  },

  methods: {
    //   获取--数据代理商流水数据 
    getDataAgentStream (data) {
      if (data) {
        getDataAgentStream(data).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.privilegeAllDataObj = DATA.data
            this.tableConfig.total = DATA.data.total
          }
        })
      } else {
        getDataAgentStream().then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.privilegeAllDataObj = DATA.data

            this.tableConfig.total = DATA.data.total
            this.tableConfig.loading = false
          }

        })
      }

    },

    //获得下列分类页的页数
    getPage () {
      if (this.searchData) {
        const DATA = {
          data_agent_id: this.dataAgent_id,
          type: this.searchData.type,
          in_out: this.searchData.in_out,
          page: this.tableConfig.page,
          page_rows: 10
        }
        this.getDataAgentStream(DATA)
      } else {
        const DATA = {
          data_agent_id: this.dataAgent_id,
          page: this.tableConfig.page,
          page_rows: 10
        }
        this.getDataAgentStream(DATA)
      }
    },

    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理
      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },
    //刷新表格数据
    updataTableData () {
      const DATA = {
        data_agent_id: this.dataAgent_id,
        page: this.tableConfig.page,
        page_rows: 10
      }
      this.getDataAgentStream(DATA)
    },
    //搜索景点元素
    searchKeyword (searchForm) {
      console.log(searchForm)
      const data = {
        data_agent_id: this.dataAgent_id,
        type: searchForm.type,
        in_out: searchForm.in_out
      }
      this.searchData = data
      this.getDataAgentStream(data)
    },
    //重置筛选条件
    resetFilter (searchForm) {
      searchForm.data_agent_id = "",
        searchForm.type = "",
        searchForm.data_agent_id = "",
        searchForm.in_out = ''
      this.searchData = ''
      this.updataTableData()
    },

    goBack () {
      this.$router.push({ name: 'dataAgent' })
    },
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 720px;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }
  /deep/ .manage-header {
    height: 35px;
  }
  .filtrateInput {
    background: #f8f7f7;
    padding: 12px 8px;
    padding-right: 45%;
    margin-top: -90px;
    width: 80%;
  }
}
</style>
